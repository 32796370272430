button {
    border: none;
    background: none;
    color: #FFF;    
    font-size: 1.2rem;
}
.icon {
    flex: 1 1;
    margin: 0 auto;
    align-self: center;
    align-items: center;
    display: flex;
    padding-top: 24px;
}