.codeContainer {
    flex: 1;
    border: 2px solid #4c4a49;;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    margin-top: 6px;
    margin-right: 1px;
    margin-left: 1px;
}
.codeText {
    font-size: 18px;
}
.codeBtnsRow {
    flex: 1;
    display: flex;
    flex-direction: row;
}
.codeBtnCell {
    cursor: pointer;
    font-size: 20px;
    background-color: #4c4a49;
    color: #ffffff;
    flex: 1;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}