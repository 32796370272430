.generatorContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.codeResult {
    font-weight: bold;
    flex: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.scheduleFormCodeContainer {
    flex: 100%;
    display: flex;
}

.scheduleFormCodeContainer .applyBtn{
    color: #fff;
}

.settingsFormCodeContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.scheduleFormCodeContainer input{
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #dfdfdf;
    padding: 3px 5px;
    font-size: 20px;
    text-align: center;
    flex: 1.8;
}

.codeParser {
    flex: 100%;
    margin-bottom: 20px;
}

.temps {
    display: flex;
    width: 100%;
}

.temps .settingSelect {
    width: 100%;
}

.controlContainer {
    flex: 1;
    text-align: left;
    margin-bottom: 15px;
    padding: 10px;
}
.controlContainer .inputContainer {
    display: block;
    text-align: left;
}

.controlName {
    font-weight: bold;
    margin-bottom: 4px;
}

.btnContainer {
    flex: 100%;
    text-align: center;
}

.weekController {
    flex: 50%;
    display: flex;
    margin: 0px 0 20px;
}
.weekDayContainer {
    flex: 1;
    justify-content: center;
    text-align: center;
}
.weekDayName {
    font-weight: bold;
    font-size: .8rem;
}
.weekDayType {
    background-color: #fff;
    cursor: pointer;
    word-break: break-all;
}
.weekDayType.home {
    background-color: green;
    color: #fff;
}
.weekDayType.away {
    background-color: orangered;
    color: #fff;
}
.daysContainer {
    flex: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.daysContainer .day {
    padding: 10px;
    flex: 20%;
    border: 1px solid #ccc;
}
.periodContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* align-items: center; */
}
.startContainer {
    margin-left: 10px;
    flex: 1;
}
.endContainer {
    margin-left: 10px;
    flex: 1;
}

.periodContainer select {
    width: 100%;
}

.periodSwitch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}

.periodValues {
    display: flex;
    flex-direction: row;
}