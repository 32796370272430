@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url('../assets/font/MYRIADPRO-REGULAR.OTF');
}

.topContainer {
  font-family: 'MyriadPro';
  background-color: #231f20;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  background-size: cover;
}

.topContainer.devi {
  background-color: #1a6694 !important;
  background-image: url('../assets/img/background.png');
}

.topContainer.danfoss {
  background-color: #e2000f !important;
}

.topContainer.danfoss .bgLogo {
  /* width: 200px;
    height: 100px; */
  width: 310px;
  height: 120px;
}

.mainBackground {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
}

.bgLogo {
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.mainContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  width: 100%;
  justify-content: center;
}

.termostat {
  height: 516px;
  width: 518px;
  position: relative;
}

.termoHolder {
  align-self: center;
  margin-top: 70px;
}

.settingsFormTopContainer {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.termostatBg {
  height: 496px;
  width: 498px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.termostatScreen {
  height: 170px;
  width: 220px;
  background-color: #231f20;
  position: absolute;
  top: 167px;
  left: 146px;
}

.switches {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
}

.switch {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: center;
  color: #fff;
  margin-right: 10px;
  margin-top: 3px;
}

.switch .label {
  z-index: 99;
  margin: 0 15px;
}

.pageTitle {
  /* font-size: 1.2rem; */
  line-height: 2.4rem;
  text-align: left;
  color: #fff;
  z-index: 99;
}

.infoText {
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: justify;
  color: #fff;
  z-index: 99;
  align-self: center;
  /* min-height: 520px; */
  flex: 1;
  padding: 40px;
  /* padding-left: 0; */
  flex-basis: 400px;
  max-width: 600px;
  margin-bottom: 100px;
}

.activeZone {
  position: absolute;
  top: 110px;
  right: 70px;
  width: 30px;
  height: 70px;
}

@media only screen and (max-device-width: 480px),
  only screen and (min-device-width: 560px) and (max-device-width: 1136px) and (-webkit-min-device-pixel-ratio: 2) {
    .switches {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .switches > div {
        margin: 5px 0;
    }
}

@media (min-width: 768px) { }

svg.pulse-svg {
  overflow: visible;  
  margin-top: 22px;
  margin-left: 2px;
}

svg.pulse-svg .first-circle {
  fill: #bebdbd;
  transform: scale(0.5);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
}
svg.pulse-svg .second-circle {
  fill: #bebdbd;
  transform: scale(0.5);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
  animation-delay: 1s;
}
svg.pulse-svg .third-circle {
  fill: #bebdbd;
  transform: scale(0.5);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
  animation-delay: 2s;
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
