.frontTopContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    user-select: none;
}
.frontTopContainer.inactive {
    cursor: pointer;
}
.temperatureContainer {
    font-size: 100px;
    color: #4e4c4a;
}
.temperatureContainer.active {
    color: #ffffff;
}
.tempSmallPart {
    font-size: 42px;
}
.touch_icon {
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 40px;
    height: 40px;
}
.touch_icon img {
    height: 40px;
}

.temp_up_icon {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    width: 38px;
    height: 18px;
}
.temp_up_icon img {
    height: 18px;
}

.temp_down_icon {
    position: absolute;
    bottom: 4px;
    right: 4px;
    cursor: pointer;
    width: 38px;
    height: 18px;
}
.temp_down_icon img {
    height: 18px;
}

.heating_icon {
    position: absolute;
    left: 5px;
    margin-top: auto;
    margin-bottom: auto;
}
.heating_icon img {
    height: 52px;
}
.menuLink {
    font-size: 22px;
    position: absolute;
    bottom: 0px;
    left: 5px;
    cursor: pointer;
    color: #ffffff;
    letter-spacing: -1px;
    font-size: 23px;
}