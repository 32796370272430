.overviewTopContainer {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.overviewContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.dayLineContainer {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    align-items: flex-end;
    flex: 1;
}
.lineDay {
    font-size: 12px;
    padding-top: 4px;
    margin-right: 22px;
    margin-left: 11px;
    width: 30px;
}
.linePoints {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 145px;
    padding-bottom: 4px;
}