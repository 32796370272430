.usageInfo {
    text-align: center;
    flex: 1;
    color: #fff;
    font-size: 1.4rem;

}
.usageInfo div {
    margin: 20px 0;
}

.usageInfo .name {
    color: #4d4b4a;
}