.dialogTopContainer {
    padding: 6px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.dialogContent {
    background-color: #4c4a49;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.dialogInfoContainer {
    flex: 1;
    color: #ffffff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialogInfoContainer span {
    text-align: center;
}

.dialogOptionsContainer {
    border-top: 1px solid #000;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
    padding-right: 5px;
    padding-left: 5px;
}
.dialogOptionsContainer img {
    height: 16px;
}
