html, body {
    overflow-x: hidden;
}

.screenTopContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.screenContent {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.color-white {
    color: #ffffff;
}

.text-center {
    text-align: center;
}