.awayPeriodTopContainer {
    padding: 6px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.awayDatesContainer {
    color: #ffffff;
    font-size: 26px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.periodDateRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 33.3% 0;
    cursor: pointer;
    border-bottom: 1px solid #656263;
}
.periodType {
    font-size: 18px;
}
.monthStr {
    text-align: center;
    font-size: 21px;
}
.dayStr {
    font-size: 56px;
    text-align: center;
}
.weekDayStr {
    font-size: 21px;
    text-align: center;
}
.calendarDayContainer {
    flex: 1;
    text-align: center;
    line-height: 1;
}