.settingsFormTopContainer {
  /* height: 496px; */
  width: 460px;
  position: relative;
  /* margin-top: 23px;
    margin-left: 26px;
    margin-right: 20px;
    margin-bottom: 68px; */
  background-color: #fefcff;
  border-radius: 5px;

  margin-top: 100px;
  margin-bottom: 70px;
}

.scheduleConatiner {
  width: 520px;
}

@media (min-width: 576px) {
  .settingsFormTopContainer {
    margin-top: 100px;
  }
}

@media (min-width: 992px) {
  .settingsFormTopContainer {
    margin-top: 70px;
  }
}

.settingsFormContainer {
  padding: 20px;
  padding-top: 5px;
}

.settingsFormCodeContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.settingsFormCodeContainer input {
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  padding: 3px 5px;
  font-size: 20px;
  text-align: center;
  min-width: 243px;
}
.applyBtn {
  cursor: pointer;
  background-color: #4c4a49;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applyBtn:hover {
  background-color: #4c4a4980;
}
.temperatureSection {
  padding-right: 6px;
  padding-left: 10px;
}

.generalSection {
  background-color: #231f20;
  color: #fff;
  padding: 12px;
  border-radius: 5px;
}

.settingsSliderField {
  /* border-bottom: 1px solid #cccccc; */
  padding-top: 10px;
}

.sliderFieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 15px;
}

.settingsSelectField {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 15px;
}

.selectFieldLabel {
  text-transform: capitalize;
}

.generatorTitle {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
}

.settingSelect {
  display: block;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1;
  padding: 5px 10px;
  width: 280px;
  max-width: 280px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid rgb(206, 206, 206);
  box-shadow: none;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
}
.settingSelect::-ms-expand {
  display: none;
}
.settingSelect:hover {
  border-color: #888;
}
.settingSelect:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  /* box-shadow: 0 0 0 3px -moz-mac-focusring; */
  color: #222;
  outline: none;
}
.settingSelect option {
  font-weight: normal;
}
.timerField {
  display: flex;
  justify-content: space-between;
}
.timerSwitcher {
  display: flex;
  width: 280px;
  width: 29px;
}
.scheduleLink {
  color: #fff;
  font-size: 0.8rem;
}
.scheduleLink a {
  color: #fff !important;
}

.switcherOption {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.switcherOption span {
  padding-right: 5px;
}

.settingsCheckboxes {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
}
.tempSectionField {
  flex: 1;
  display: flex;
}
.tempSectionCheckbox, .breakoutCheckbox {
  cursor: pointer;
  margin-left: 10px;
}

.sliderFieldValue {
  font-weight: bold;
}

.codeError {
  border-radius: 3px;
  background-color: #ffffff;
  color: #cc0000;
  padding: 5px;
  text-align: center;
  margin-bottom: 8px;
}

.shareBtns {
  text-align: center;
  margin-top: 20px;
}

.sendInput {
  padding: 10px 20px 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid #c2c1c1;
}

.sendBtn {
  border: none;
  padding: 8px 20px;
  background-color: #1a6594;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
}
.sendBtn:hover {
  background-color: rgb(30, 116, 170);
}

.danfoss .sendBtn {
  background-color: #e2000f;
}
.danfoss .sendBtn:hover {
  background-color: #bb0511;
}

small {
  margin-bottom: 15px;
  display: block;
  font-size: 0.8rem;
}

.week {
  display: flex;
  flex-direction: column;
}

.week .day {
  display: flex;
  border-bottom: 1px solid #dfdfdf !important;
}

.week .day:first-child {
  border-top: 1px solid #dfdfdf;
  margin-top: 15px;
}

.week .day .dayName {
  align-self: center;
  width: 120px;
}

.week .day .timer {
  display: flex;
  flex-direction: column;
}

.week .day .timer .period {
  display: flex;
  align-items: center;
}

.week .day .timer .period .timers {
  padding: 5px 20px;
}
