.menuTopContainer {
    background-image: url('../../assets/img/menuBg.svg');
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
}

.homeBtnContainer {
    position: absolute;
    top: 70px;
    left: 95px;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 100;
}
.homeBtn {
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-top: 4px;
    margin-left: 6px;
}

.menuContainer {
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}
.menuItem {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3px;
    cursor: pointer;
    box-sizing: border-box;
    height: 85px;
}
.timerIcon {
    height: 33px;
}
.awayIcon {
    height: 33px;
}
.settingsIcon {
    height: 42px;
}
.menuItemTitle {
    margin-top: 5px;
    font-size: 21px;
    color: #ffffff;
}

.timeText {
    color: #4d4b4a;
    font-size: 20px;
    margin-bottom: 3px;
}
.dateText {
    color: #4d4b4a;
    font-size: 18px;
    line-height: 1;
}