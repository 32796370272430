.listContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 21px;
}

.listContainer>.listItemsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.listContainer .listItem {
    flex: 33.3% 0;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
}

.listContainer.compact {
    padding-top: 2px;
    font-size: 11px;
}

.listContainer.compact .borderedItem {
    border-bottom: none;
}

.listContainer.compact .listItem {
    flex: 12.5% 0;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
}

.listContainer .listItemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-right: 2px;
    margin-left: 4px;
    user-select: none;
}
.listContainer .valuePreview {
    color: #4d4b4a;
}
.borderedItem {
    border-bottom: 1px solid #656263;
}

.scrollContainer {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.scrollContainer .scrollTop {
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}
.scrollContainer .scrollBar {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    user-select: none;
    position: relative;
}
.scrollContainer .scrollBar img{
    position: absolute;
}

.scrollContainer .scrollBottom {
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.listItemTitle {
    display: flex;
    align-items: center;
}
.listItemTitle img {
    margin-right: 10px;
}