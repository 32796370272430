.onOffDialogTopContainer {
    padding: 6px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.onOffContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 21px;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-evenly;
}
.onOffDivider {
    color: #e1e0ddff;
}

.onOffOption {
    color: #e1e0ddff;
    cursor: pointer;
}
.onOffOption.selected {
    color: #000000;
}