.setupFooter {
    background-color: #4d4b4a;
    height: 36px;
    font-size: 22px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.setupFooter .infoIcon {
    height: 20px;
    padding-left: 8px;
}
.setupFooter .stepsText {
    padding-right: 8px;
}
.setupStartText {
    color: #ffffff;
    width: 100%;
    text-align: center;
    line-height: 1;
}
.controlValueContainer {
    flex: 1;
    text-align: center;
    line-height: 1;
    font-size: 22px;
}
.sensorValueContainer {
    flex: 1;
    text-align: center;
    line-height: 1;
    font-size: 22px;
}
.floorTypeValueContainer {
    flex: 1;
    text-align: center;
    line-height: 1;
    font-size: 22px;
}
.timerStateValue {
    flex: 1;
    text-align: center;
    line-height: 1;
    font-size: 22px;
}
.finishSetupTopContainer {
    padding: 6px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.finishContent {
    background-color: #4c4a49;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.finishInfoContainer {
    flex: 1;
    color: #ffffff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.finishConfirmContainer {
    border-top: 1px solid #000;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
}
.finishConfirmContainer img {
    height: 16px;
}