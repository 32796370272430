.daysTopContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

/* cells styles */
.daysRow {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.daysRow~.daysRow {
    border-top: 1px solid #656263;
}
.dayCell {
    flex: 33.3%;
    font-size: 22px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.dayCell~.dayCell {
    border-left: 1px solid #656263;
}
.dayCell.selected {
    background-color: #ffffff;
    color: #000000;
}
.dayCell.inactive {
    color: #4d4b4a;
}

.infoIcon {
    height: 25px;
    width: 25px;
}