.pickerContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 22px;
    align-items: center;
    background-color: #ffffff;
}

.prevPanel {
    display: flex;
    height: 100%;
    padding-left: 6px;
    align-items: center;
    cursor: pointer;
}
.prevIcon {
    height: 64px;
}
.valueContainer {
    flex: 1;
    text-align: center;
    line-height: 1;
    font-size: 56px;
}
.nextPanel {
    display: flex;
    height: 100%;
    padding-right: 6px;
    align-items: center;
    cursor: pointer;
}
.nextIcon {
    height: 64px;
}