.timeContainer {
    display: flex;
    flex-direction: row;
    font-size: 50px;
    color: #ffffff;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.timePart {
    cursor: pointer;
}

.dateContainer {
    display: flex;
    flex-direction: row;
    font-size: 46px;
    color: #ffffff;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.datePart {
    cursor: pointer;
}