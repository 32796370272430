.textContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 100%;
    overflow: hidden;    
}

.textContainer>.textItemsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;    
    color: #FFF;
    padding: 5px;
    overflow: hidden;
}

.textContainer>.textItemsContainer p {
    margin: 2px 0;
}

.textContainer > .textItemsContainer > .inner {
    width: 100%;
    overflow-y: scroll;
    padding-right: 22px;
}

.textContainer .textItem {
    flex: 33.3% 0;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
}

.textContainer.compact {
    padding-top: 2px;
    font-size: 11px;
}

.textContainer.compact .borderedItem {
    border-bottom: none;
}

.textContainer.compact .textItem {
    flex: 12.5% 0;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
}

.textContainer .textItemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-right: 2px;
    margin-left: 4px;
    user-select: none;
}
.textContainer .valuePreview {
    color: #4d4b4a;
}
.borderedItem {
    border-bottom: 1px solid #656263;
}

.scrollContainer {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.scrollContainer .scrollTop {
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}
.scrollContainer .scrollBar {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    user-select: none;
    position: relative;
    padding: 10px 0 20px;
}
.scrollContainer .scrollBar img{
    position: absolute;
}

.scrollContainer .scrollBottom {
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.textItemTitle {
    display: flex;
    align-items: center;
}
.textItemTitle img {
    margin-right: 10px;
}