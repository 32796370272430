.screenHeader {
    background-color: #4d4b4a;
    height: 36px;
    font-size: 22px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
}
.screenTitle {
    line-height: 1;
    font-size: 24px;
    white-space: nowrap;
}
.screenTitle.longTitle {
    font-size: 19px;
}
.titleContainer {
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1 1;
    overflow: hidden;
}
.titleContainer.longTitle {
    padding-right: 8px;
    padding-left: 8px;
}
.backIcon {
    height: 14px;
    width: 14px;
}
.backIconContainer {
    cursor: pointer;
    padding-left: 8px;
    padding-right: 12px;
}
.headerDivider {
    border-right: 1px solid #231f20;
    height: 100%;
    max-height: 50%;
}
.titleIcon {
    margin-right: 8px;
}

.confirmIconContainer {
    cursor: pointer;
    padding-right: 8px;
    padding-left: 12px;
}
.confirmIcon {
    height: 17px;
}