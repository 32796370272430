.scheduleTopContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.periodsTitle {
    flex: 1;
    width: 100%;
    color: #4d4b4a;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.periodRow {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #ffffff;
    font-size: 24px;
    justify-content: center;
}
.checkIconContainer {
    margin-right: 10px;
}
.checkIcon {
    cursor: pointer;
    height: 20px;
}
.periodTime {
    line-height: 1;
    margin-left: 10px;
}
.timeItem {
    cursor: pointer;
}