.screenHeaderSm {
    background-color: #4d4b4a;
    height: 34px;
    font-size: 22px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding-left: 4px; */
}

.screenTitleContainerSm {
    flex: 1;
    display: flex;
    justify-content: center;
}
.screenTitleSm {
    line-height: 1;
    font-size: 21px;
    white-space: nowrap;
}

.backIconContainerSm {
    cursor: pointer;
    flex: 20% 0;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}
.backIconSm {
    height: 23px;
    padding-right: 10px;
}
.headerDividerSm {
    border-right: 1px solid #231f20;
    height: 100%;
    max-height: 50%;
}

.confirmIconContainerSm {
    cursor: pointer;
    flex: 20% 0;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}
.confirmIconSm {
    height: 26px;
    padding-left: 10px;
}

.titleIconSm {
    height: 23px;
    margin-right: 13px;
}
